<template>
  <div>
    <v-alert v-if="lock" class="mb-0" outlined type="warning">
      El tiempo de la comisión a <strong>finalizado</strong> y se encuentra
      <strong>bloqueada</strong>, por favor contacte con el equipo de workforce.
    </v-alert>

    <v-card elevation="0" :disabled="lock">
      <v-row class="mt-0 pt-0 px-5" justify="center">
        <v-col cols="12" class="py-0">
          <h2 class="color-text-main pt-4 pb-2">DETALLES DE LA COMISIÓN</h2>
          <p class="info-text mb-0">
            <strong> Nombre: </strong> {{ infoCommissions.name }}
          </p>
          <p class="info-text mb-0">
            <strong> Fecha: </strong> {{ infoCommissions.date }}
          </p>
          <p class="info-text mb-0">
            <strong> Estado:</strong>
            <v-chip
              label
              small
              dark
              class="ml-1"
              :color="getColorStatus(infoCommissions.status.id)"
            >
              {{ infoCommissions.status.name }}
            </v-chip>
          </p>
          <p class="info-text mb-0">
            <strong> País: </strong>
            {{ infoCommissions.country }}
          </p>
        </v-col>
        <v-col class="pb-0" cols="12">
          <v-alert :color="alert1.type" dense outlined>
            La comisión fue creada hace <strong> {{ days }} día(s)</strong>, no
            se ha completado.
          </v-alert>
        </v-col>
        <v-col class="pt-0" cols="12">
          <v-alert
            v-html="messageAlert"
            :color="alert1.typeHolidays"
            dense
            outlined
          >
          </v-alert>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
          <p class="color-text-main text-left mt-4 mb-4">
            <strong> Archivos </strong>
          </p>
          <v-row>
            <v-col
              v-if="
                infoCommissions.status_id == STATUS.rejectedDirectory ||
                infoCommissions.status_id == STATUS.rejectedManager
              "
              class="pt-0"
              cols="12"
            >
              <v-alert
                icon="mdi-information-outline"
                prominent
                text
                type="warning"
              >
                <b>Se ha rechazado la comisión</b>
              </v-alert>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <!-- ARCHIVOS ACTIVOS -->

          <v-expansion-panels
            v-model="openActive"
            active-class
            readonly
            multiple
            class="mb-5 border-active"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <strong> CONSOLIDADO ACTUAL </strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-timeline dense clipped>
                  <v-timeline-item
                    v-for="(data, i) in listHistoryActive"
                    :key="'i' + i"
                    :color="data.color"
                    class="mb-4 text-left"
                    small
                  >
                    <v-row justify="space-between">
                      <v-col style="color: #6f6f6f" cols="7">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              class="white--text ml-0 mr-3"
                              color="#034f79"
                              label
                              small
                            >
                              {{ data.commission_users.actions.name }}
                            </v-chip>
                          </template>
                          <span>{{ data.user.name }}</span>
                        </v-tooltip>
                        <strong> Usuario: </strong>
                        {{ data.user.document_number }}

                        <strong> Comentario: </strong>
                        <template v-if="data.comments != null">
                          {{ data.comments }}
                        </template>
                        <template v-else>
                          <small> sin comentario </small>
                        </template>

                        <!-- Acciones en la comisión por parte de ese usuario -->
                        <template v-if="data.commision_url">
                          <v-btn
                            small
                            color="#E95233"
                            @click="download(data.commision_url)"
                            text
                          >
                            <u> Descargar comisiones </u>
                          </v-btn>
                          <strong> Monto de la comisión: </strong>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <strong
                                v-bind="attrs"
                                v-on="on"
                                style="color: #e95233; font-size: 18px"
                              >
                                {{ validateCOP(data.commission_amount) }}
                              </strong>
                            </template>
                            <span>Monto total de la comisión</span>
                          </v-tooltip>
                        </template>

                        <template v-else>
                          <p class="mb-0 mt-1" style="color: #2096f4">
                            <strong>
                              La comisión no tiene archivo de comisiones
                            </strong>
                          </p>
                        </template>

                        <template v-if="data.variables_url">
                          <v-btn
                            small
                            color="#034f79"
                            @click="download(data.variables_url)"
                            text
                          >
                            <u> Descargar variables </u>
                          </v-btn>
                          <strong> Monto de las variables: </strong>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <strong
                                v-bind="attrs"
                                v-on="on"
                                style="color: #034f79; font-size: 18px"
                              >
                                {{ validateCOP(data.variable_amount) }}
                              </strong>
                            </template>
                            <span>Monto total de las variables</span>
                          </v-tooltip>
                        </template>
                        <template v-else>
                          <p style="color: #2096f4">
                            <strong>
                              La comisión no tiene archivo de compensación
                              variable
                            </strong>
                          </p>
                        </template>
                      </v-col>
                      <v-col class="text-right" cols="5">
                        <template
                          v-if="
                            data.commission_users.action_id != ACTIONS.workforce
                          "
                        >
                          <v-tooltip v-if="data.approval == 1" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                label
                                small
                                color="green"
                                class="mr-3"
                                dark
                              >
                                <strong> APROBADO </strong>
                              </v-chip>
                            </template>
                            <span>Aprobado</span>
                          </v-tooltip>
                          <v-tooltip v-else bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                label
                                small
                                color="#b71c1c"
                                class="mr-3"
                                dark
                              >
                                <strong> NO APROBADO </strong>
                              </v-chip>
                            </template>
                            <span>No aprobado</span>
                          </v-tooltip>
                        </template>
                        <v-chip
                          label
                          dark
                          small
                          class="mr-2"
                          :color="getColorStatus(data.status_id)"
                        >
                          {{ data.status.name }}
                        </v-chip>

                        <template v-if="loading.btnA">
                          <v-progress-circular
                            :width="3"
                            color="red"
                            indeterminate
                          ></v-progress-circular>
                        </template>
                        <template v-else>
                          <v-tooltip
                            v-if="
                              data.status.id == STATUS.active &&
                              (infoCommissions.status_id ==
                                STATUS.pendingApprovedDirectory ||
                                infoCommissions.status_id == STATUS.pending) &&
                              turn
                            "
                            bottom
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="openModalApprove(data)"
                                v-bind="attrs"
                                v-on="on"
                                x-small
                                color="green"
                                dark
                                fab
                              >
                                <v-icon small>mdi-check</v-icon>
                              </v-btn>
                            </template>
                            <span>Aprobar comisión</span>
                          </v-tooltip>

                          <v-tooltip
                            v-if="
                              data.status.id == STATUS.active &&
                              (infoCommissions.status_id ==
                                STATUS.pendingApprovedDirectory ||
                                infoCommissions.status_id == STATUS.pending) &&
                              turn
                            "
                            bottom
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="ml-2"
                                @click="openModalRejected(data)"
                                v-bind="attrs"
                                v-on="on"
                                x-small
                                color="red"
                                dark
                                fab
                              >
                                <v-icon small dark>mdi-close</v-icon>
                              </v-btn>
                            </template>
                            <span>Rechazar comisión</span>
                          </v-tooltip>
                        </template>

                        <!-- {{ new Date(data.created_at).toISOString().substr(0, 10) }} -->
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                </v-timeline>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0" cols="3">
          <v-layout justify-start>
            <v-btn
              class="ml-5"
              @click="modal.history = true"
              dark
              color="#E95233"
            >
              HISTORIAL DE ACCIONES DE LA COMISIÓN</v-btn
            >
          </v-layout>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog
      v-model="modal.history"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="#E95233">
          <v-btn icon dark @click="modal.history = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <strong> HISTORIAL DE ACCIONES DE LA COMISIÓN </strong>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="modal.history = false"> Cerrar </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="8">
              <v-layout justify-start>
                <p class="mt-8 pl-10" style="font-size: 16px">
                  <strong>
                    Este es el historial de la comisión, aquí enontrará todas
                    las acciones o actividad que ha realizado cada uno de los
                    participantes de la comisión.
                  </strong>
                </p>
              </v-layout>

              <v-alert
                class="mb-0 mt-6"
                v-if="listHistory.length == 0"
                outlined
                type="info"
              >
                No se encontró historial de esta comisión
              </v-alert>

              <v-timeline dense clipped class="mt-6">
                <!-- GRIS #BDBDBD -->
                <v-timeline-item
                  v-for="(data, i) in listHistory"
                  :key="'i' + i"
                  :color="data.color"
                  class="mb-4 text-left"
                  small
                >
                  <v-row justify="space-between">
                    <v-col style="color: #6f6f6f" cols="7">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            class="white--text ml-0 mr-3"
                            color="#034f79"
                            label
                            small
                          >
                            {{ data.commission_users.actions.name }}
                          </v-chip>
                        </template>
                        <span>{{ data.user.name }}</span>
                      </v-tooltip>
                      <strong> Usuario: </strong>
                      {{ data.user.document_number }}
                      <strong v-if="data.comments"> Comentario: </strong>
                      {{ data.comments }}

                      <!-- Acciones en la comisión por parte de ese usuario -->
                      <template v-if="data.commision_url">
                        <v-btn
                          small
                          color="#E95233"
                          @click="download(data.commision_url)"
                          text
                        >
                          <u> Descargar comisiones </u>
                        </v-btn>
                        <strong> Monto de la comisión: </strong>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <strong
                              v-bind="attrs"
                              v-on="on"
                              style="color: #e95233; font-size: 18px"
                            >
                              {{ validateCOP(data.commission_amount) }}
                            </strong>
                          </template>
                          <span>Monto total de la comisión</span>
                        </v-tooltip>
                      </template>

                      <template v-else>
                        <p class="mb-0 mt-1" style="color: #2096f4">
                          <strong>
                            La comisión no tiene archivo de comisiones
                          </strong>
                        </p>
                      </template>

                      <template v-if="data.variables_url">
                        <v-btn
                          small
                          color="#034f79"
                          @click="download(data.variables_url)"
                          text
                        >
                          <u> Descargar variables </u>
                        </v-btn>
                        <strong> Monto de las variables: </strong>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <strong
                              v-bind="attrs"
                              v-on="on"
                              style="color: #034f79; font-size: 18px"
                            >
                              {{ validateCOP(data.variable_amount) }}
                            </strong>
                          </template>
                          <span>Monto total de las variables</span>
                        </v-tooltip>
                      </template>
                      <template v-else>
                        <p style="color: #2096f4">
                          <strong>
                            La comisión no tiene archivo de compensación
                            variables
                          </strong>
                        </p>
                      </template>
                    </v-col>
                    <v-col class="text-right" cols="5">
                      <template
                        v-if="
                          data.commission_users.action_id != ACTIONS.workforce
                        "
                      >
                        <v-tooltip v-if="data.approval == 1" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              label
                              small
                              color="green"
                              class="mr-3"
                              dark
                            >
                              <strong> APROBADO </strong>
                            </v-chip>
                          </template>
                          <span>Aprobado </span>
                        </v-tooltip>
                        <v-tooltip v-else bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              label
                              small
                              color="#b71c1c"
                              class="mr-3"
                              dark
                            >
                              <strong> NO APROBADO </strong>
                            </v-chip>
                          </template>
                          <span>No aprobado</span>
                        </v-tooltip>
                      </template>
                      <v-chip
                        label
                        dark
                        small
                        class="mr-2"
                        :color="getColorStatus(data.status_id)"
                      >
                        {{ data.status.name }}
                      </v-chip>

                      <v-tooltip
                        v-if="
                          data.status.id == STATUS.active &&
                          infoCommissions.status_id ==
                            STATUS.pendingCoordinator &&
                          turn
                        "
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="openModalApprove(data)"
                            v-bind="attrs"
                            v-on="on"
                            x-small
                            color="green"
                            dark
                            fab
                          >
                            <v-icon small>mdi-check</v-icon>
                          </v-btn>
                        </template>
                        <span>Aprobar comisión</span>
                      </v-tooltip>

                      <v-tooltip
                        v-if="
                          data.status.id == STATUS.active &&
                          infoCommissions.status_id ==
                            STATUS.pendingCoordinator &&
                          turn
                        "
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ml-2"
                            @click="openModalRejected(data)"
                            v-bind="attrs"
                            v-on="on"
                            x-small
                            color="red"
                            dark
                            fab
                          >
                            <v-icon small dark>mdi-close</v-icon>
                          </v-btn>
                        </template>
                        <span>Rechazar comisión</span>
                      </v-tooltip>

                      <!-- {{ new Date(data.created_at).toISOString().substr(0, 10) }} -->
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modal.approved" max-width="400">
      <v-card>
        <v-card-title class="pb-0">
          <v-layout justify-center>
            <p class="color-text-main mb-0">
              <v-icon x-large color="#E95233"> mdi-alert </v-icon>
            </p>
          </v-layout>
        </v-card-title>
        <v-card-title class="pt-0">
          <v-layout justify-center>
            <p class="color-text-main mb-0">Aprobar comisión</p>
          </v-layout>
        </v-card-title>

        <v-card-text>
          <p class="text-info mb-2">
            ¿Estás seguro de <strong> aprobar </strong> la comisión?
          </p>
          <p v-if="infoModal.commision_url != null" class="mb-2">
            Monto total de la comisión
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <strong
                  v-bind="attrs"
                  v-on="on"
                  style="color: #e95233; font-size: 18px"
                >
                  {{ validateCOP(commisionsAmount) }}
                </strong>
              </template>
              <span>Monto total de la comisión</span>
            </v-tooltip>
          </p>
          <p v-if="infoModal.variables_url != null">
            Monto total de las variables
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <strong
                  v-bind="attrs"
                  v-on="on"
                  style="color: #034f79; font-size: 18px"
                >
                  {{ validateCOP(variableAmount) }}
                </strong>
              </template>
              <span>Monto total de las variables</span>
            </v-tooltip>
          </p>
          <form ref="form1">
            <v-textarea
              :counter="1000"
              :rules="[
                (v) => v.length <= 1000 || 'Superó los 1000 caracteres.',
              ]"
              color="#034f79"
              v-model="form1.comments"
              outlined
              rows="3"
              label="Observación"
            >
            </v-textarea>
          </form>
        </v-card-text>
        <v-card-actions>
          <v-col class="py-0" cols="6">
            <v-layout justify-center>
              <v-btn
                :loading="loading.btn"
                class="mb-5"
                @click="modal.approved = false"
                color="#c1c1c1"
                dark
                rounded
                block
              >
                Cerrar
              </v-btn>
            </v-layout>
          </v-col>
          <v-col class="py-0" cols="6">
            <v-layout justify-center>
              <v-btn
                :loading="loading.btn"
                class="mb-5"
                @click="approved()"
                color="#034f79"
                dark
                rounded
                block
              >
                Aprobar
              </v-btn>
            </v-layout>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modal.rejected" max-width="500">
      <v-card>
        <v-card-title class="pb-0">
          <v-layout justify-center>
            <p class="color-text-main mb-0">
              <v-icon x-large color="#E95233"> mdi-alert </v-icon>
            </p>
          </v-layout>
        </v-card-title>
        <v-card-title class="pt-0">
          <v-layout justify-center>
            <p class="color-text-main mb-0">Rechazar comisión</p>
          </v-layout>
        </v-card-title>

        <v-card-text>
          <p class="text-info mb-2">
            ¿Estás seguro de <strong> rechazar </strong> la comisión?
          </p>
          <p class="mb-2" v-if="infoModal.commision_url">
            Monto total de la comisión
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <strong
                  v-bind="attrs"
                  v-on="on"
                  style="color: #e95233; font-size: 18px"
                >
                  {{ validateCOP(commisionsAmount) }}
                </strong>
              </template>
              <span>Monto total de la comisión</span>
            </v-tooltip>
          </p>
          <p v-if="infoModal.variables_url">
            Monto total de las variables
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <strong
                  v-bind="attrs"
                  v-on="on"
                  style="color: #034f79; font-size: 18px"
                >
                  {{ validateCOP(variableAmount) }}
                </strong>
              </template>
              <span>Monto total de la variable </span>
            </v-tooltip>
          </p>
          <p class="mb-2">
            Adjunte el archivo con las correciones correspondientes.
          </p>

          <v-form ref="form">
            <v-file-input
              v-if="infoModal.commision_url"
              color="#034f79"
              v-model="form.file"
              outlined
              rows="3"
              label="Adjuntar archivo de comisiones"
            >
            </v-file-input>
            <v-file-input
              v-if="infoModal.variables_url"
              color="#034f79"
              v-model="form.file2"
              outlined
              rows="3"
              label="Adjuntar archivo de variable"
            >
            </v-file-input>
            <v-select
              v-model="form.reason"
              :items="listReason"
              item-text="name"
              item-value="id"
              label="Seleccione una razón"
              outlined
              color="#034f79"
            ></v-select>
            <v-textarea
              :counter="1000"
              :rules="[
                (v) => !!v || 'Este campo es requerido',
                (v) => v.length <= 1000 || 'Superó los 1000 caracteres.',
              ]"
              color="#034f79"
              v-model="form.comments"
              outlined
              rows="3"
              label="Observación"
            >
            </v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-col class="py-0" cols="6">
            <v-layout justify-center>
              <v-btn
                :loading="loading.btn"
                class="mb-5"
                @click="modal.rejected = false"
                color="#c1c1c1"
                dark
                rounded
                block
              >
                Cerrar
              </v-btn>
            </v-layout>
          </v-col>
          <v-col class="py-0" cols="6">
            <v-layout justify-center>
              <v-btn
                :loading="loading.btn"
                class="mb-5"
                @click="rejected()"
                color="#034f79"
                dark
                rounded
                block
              >
                Rechazar
              </v-btn>
            </v-layout>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="loading.all">
      <v-progress-circular
        color="#e95233"
        width="8"
        indeterminate
        size="100"
      ></v-progress-circular
      ><br />
      <v-layout justify-center>
        <label class="mt-4" style="font-size: 20px">Cargando...</label>
      </v-layout>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/utils/api";
import alert from "@/mixins/alert";
import color from "@/mixins/color";
import { validateHolidays } from "./../../../utils/holidays";
import { STATUS, ACTIONS, REASONREJECTED } from "./../../../utils/util";
export default {
  mixins: [alert, color],
  data() {
    return {
      REASONREJECTED: REASONREJECTED,
      ACTIONS: ACTIONS,
      STATUS: STATUS,
      lock: false,
      turn: false,
      days: null,
      daysHolidays: null,
      listHistory: [],
      listHistoryActive: [],
      openActive: [0],
      id: this.$route.params.commissionId,
      loading: {
        table: false,
        btn: false,
        all: false,
        btnA: false,
      },
      infoCommissions: {
        name: null,
        date: null,
        status: {
          id: null,
          name: null,
        },
      },
      form1: {
        comments: "",
        approval: 1,
      },
      form: {
        comments: "",
        file: null,
        file2: null,
        approval: 0,
        reason: null,
      },
      modal: {
        approved: false,
        rejected: false,
        history: false,
      },
      alert1: {
        type: "",
        typeHolidays: "",
      },
      commisionsAmount: null,
      variableAmount: null,
      listReason: [],
      messageAlert: "",
      infoModal: {},
    };
  },
  watch: {
    "$route.params": function (e) {
      this.getDetail();
      this.getHistory();
      this.id = e.commissionId;
    },
  },
  methods: {
    validateCOP(value) {
      return new Intl.NumberFormat("es-CO", {
        currency: "COP",
        style: "currency",
      }).format(value);
    },
    download(data) {
      window.open(data, "_blank	");
    },
    openModalApprove(data) {
      this.infoModal = data;
      this.modal.approved = true;
      this.commisionsAmount = data.commission_amount;
      this.variableAmount = data.variable_amount;
    },
    openModalRejected(data) {
      this.modal.rejected = true;
      this.infoModal = data;
      this.commisionsAmount = data.commission_amount;
      this.variableAmount = data.variable_amount;
    },
    approved() {
      this.loading.btn = true;
      this.form1.commissionId = this.id;
      Api.Directory()
        .approvedCommission(this.form1)
        .then((res) => {
          if (res.data.cod == 0) {
            this.getDetail();
            this.getHistory();
            this.getTurn();
            this.modal.approved = false;
            this.alert(res.data.message, "Muy bien", "success");
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => (this.loading.btn = false));
    },
    rejected() {
      if (this.$refs.form.validate()) {
        if (this.form.file == null && this.form.file2 == null) {
          this.alert(
            "Debe por lo menos ingresar un archivo de comisiones",
            "Oops...",
            "error"
          );
          return false;
        }
        const formData = new FormData();
        formData.append("reasonRejection", this.form.reason);
        if (this.form.file != null) {
          formData.append("commission_file", this.form.file);
        }
        if (this.form.file2 != null) {
          formData.append("variable_file", this.form.file2);
        }
        formData.append("commissionId", this.id);
        formData.append("approval", this.form.approval);
        formData.append("comment", this.form.comments);
        this.loading.btn = true;
        Api.Directory()
          .approvedCommission(formData)
          .then((res) => {
            if (res.data.cod == 0) {
              this.getDetail();
              this.getHistory();
              this.getTurn();
              this.modal.rejected = false;
              this.$refs.form.reset();
              this.alert(res.data.message, "Muy bien", "success");
            } else {
              this.alert(res.data.message, "Oops...", "error");
            }
          })
          .catch((error) => {
            this.alert(error.response.data.message, "Oops...", "error");
          })
          .finally(() => (this.loading.btn = false));
      }
    },
    getHistory() {
      this.loading.all = true;
      Api.Auth()
        .getHistory(this.id)
        .then((res) => {
          if (res.data.cod == 0) {
            this.listHistory = res.data.data;
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
    getHistoryActive() {
      this.loading.all = true;
      Api.Auth()
        .getHistoryActive(this.id)
        .then((res) => {
          if (res.data.cod == 0) {
            this.listHistoryActive = res.data.data;
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
    showAlertCommission() {
      var initDate = new Date(this.infoCommissions.created_at).getTime();
      var currentDate = new Date().getTime();
      var diff = currentDate - initDate;
      this.days = (diff / (1000 * 60 * 60 * 24)).toFixed();
      if (this.days <= 2) {
        this.alert1.type = "#4caf50";
      } else if (this.days >= 3 && this.days <= 4) {
        this.alert1.type = "#fb8c00";
      } else if (this.days >= 5) {
        this.alert1.type = "#ff5252";
      }
    },
    showAlertCommissionHolidays() {
      var currentDate = new Date(this.infoCommissions.date);
      var formatYYYYMMDD =
        currentDate.getFullYear() +
        "/" +
        parseInt(currentDate.getMonth() + 1) +
        "/20";
      var initDate = new Date(formatYYYYMMDD).getTime();
      var currentDate = new Date().getTime();
      var diff = initDate - currentDate;
      this.daysHolidays = (diff / (1000 * 60 * 60 * 24)).toFixed();
      if (this.daysHolidays < 0) {
        this.messageAlert =
          "Se <strong> cumplieron los días </strong> para finalizar con la comisón.";
        this.alert1.typeHolidays = "#ff5252";
      } else if (this.daysHolidays >= 0 && this.daysHolidays <= 3) {
        this.messageAlert =
          "Faltan <strong> " +
          this.daysHolidays +
          " día(s)</strong>, para el cumplir con la fecha.";

        this.alert1.typeHolidays = "#fb8c00";
      } else if (this.daysHolidays >= 4) {
        this.messageAlert =
          "Faltan <strong> " +
          this.daysHolidays +
          " día(s)</strong>, para el cumplir con la fecha.";

        this.alert1.typeHolidays = "#4caf50";
      }
    },
    getDetail() {
      this.loading.all = true;
      Api.Auth()
        .getDetails(this.id)
        .then((res) => {
          if (res.data.cod == 0) {
            this.infoCommissions = res.data.data;
            this.lock = this.infoCommissions.lock_check == 1 ? true : false;

            this.showAlertCommission();
            this.showAlertCommissionHolidays();
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
    getColorStatus(status) {
      var color = this.color(status);
      return color;
    },
    calendarHolidays(formatYYYYMMDD, formatMMDDYYYY) {
      var weekday = new Date(formatYYYYMMDD).getDay();
      var holiday = validateHolidays(formatMMDDYYYY);
      if (holiday == true || weekday == 6 || weekday == 0) {
        var day1 = new Date("02/19/2022");
        var day2 = new Date("02/21/2022");
        var difference = day2.getTime() - day1.getTime();
        var day3 = new Date(difference);
        this.calendarHolidays();
      }
    },
    reasonRejected() {
      this.loading.btn = true;
      Api.Auth()
        .reasonRejected(this.id)
        .then((res) => {
          if (res.data.cod == 0) {
            this.listReason = res.data.data;
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.btn = false;
        });
    },
    getTurn() {
      this.loading.btnA = true;
      Api.Auth()
        .getTurn(this.id)
        .then((res) => {
          this.loading.btnA = false;
          if (res.data.cod == 0) {
            this.turn = res.data.data;
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.btnA = false;
        });
    },
  },
  mounted() {
    this.getHistoryActive();
    this.getTurn();
    this.getDetail();
    this.getHistory();
    this.reasonRejected();
    var currentDate = new Date();
    var formatYYYYMMDD =
      currentDate.getFullYear() +
      "/" +
      parseInt(currentDate.getMonth() + 1) +
      "/20";
    var formatMMDDYYYY =
      "20/" +
      parseInt(currentDate.getMonth() + 1) +
      "/" +
      currentDate.getFullYear();
    this.calendarHolidays(formatYYYYMMDD, formatMMDDYYYY);
  },
};
</script>
<style>
.info-text {
  color: rgba(0, 0, 0, 0.6);
  text-transform: capitalize;
}
.border-active {
  border: 2px solid #2196f3;
}
</style>
